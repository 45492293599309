import React, { useEffect, useState } from "react";

import { Button, Table } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

import Request from "../../../Request";
import LoadingScreen from "../../ReusableComponents/LoadingScreen";
import DefaultButton from "../../ReusableComponents/DefaultButton";
import Search from "../../ReusableComponents/Search";
import UserBoard from "./UserBoard";
import Pagination from '@material-ui/lab/Pagination';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Snackbar, TextField, Tabs, Tab } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../../actions/AppActions";
import { useHistory } from "react-router";
import TabPanel from "../../ReusableComponents/TabPanel";
import InactiveUsersTable from "./InactiveUsersTable";

const UserList = (props) => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search_term, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [max_page, setMaxPage] = useState(1);
    const [suggestions, SetSuggestions] = useState([]);

    const [showSnackbar, SetShowSnackbar] = useState(false);
    const [snackbarMessage, SetSnackbarMessage] = useState("");
    const [tab, SetTab] = useState(0);

    const history = useHistory();
    const dispatch = useDispatch();

    const SummonUserList = (user_list) => {
        let temp = [];
        for (let i = 0; i < user_list.length; i++) {
            let element = (
                <UserBoard
                    key={user_list[i]["id"]}
                    id={user_list[i]["id"]}
                    name={user_list[i]["name"]}
                    email={user_list[i]["email"]}
                    role={user_list[i]["role"]}
                    created_date={user_list[i]["created_at"]}
                    onDelete={() => {
                        LoadUsers();
                        SetShowSnackbar(true);
                        SetSnackbarMessage("Usuário deletado com sucesso");
                    }}
                />
            );
            temp.push(element);
        }
        setUsers(temp);
        setLoading(false);
    }

    const LoadUsers = async (search='', load = false) => {
        let token = localStorage.getItem("token");
        if (!token) return;
        setLoading(true);
        let response;
        // let response = await Request.Get(`register/${search}?page=${page}`, {"Authorization": `Bearer ${token}`});
        response = await Request.Get(`register/get-paginated?page=${page}&search=${search}`, {"Authorization": `Bearer ${token}`})
        
        if (!response) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        SummonUserList(response["data"]);
        setMaxPage(response["last_page"]);
    }

    const GetAllUsers = async () => {
        let token = localStorage.getItem("token");
        if (!token) return;

        let response = await Request.Get(`register`, { "Authorization": `Bearer ${token}` });
        if (response === undefined) return;
        if (response['message']=='Unauthenticated.') {
            dispatch(logout());
            return;
        }
        SetSuggestions(response);
    }

    useEffect(() => {
        LoadUsers(search_term);
    }, [page, search_term]);

    useEffect(() => setPage(1), [search_term]);

    useEffect(() => {
        if (props.location.state && props.location.state.snackbar) {
            SetShowSnackbar(props.location.state.snackbar.show);
            SetSnackbarMessage(props.location.state.snackbar.message);
        }
        GetAllUsers();
    }, []);

    return(
        <div style={{position: "relative"}}>
            <div className="card">
                <div className="card-body">
                    <h2 className="title">Lista de usuários</h2>
                    <p className="text-muted">Aqui são listados todos os usuários do sistema</p>
                    <hr/>
                    <div className="flex jcsb flex-wrap margin-bottom">
                        <Search
                           placeholder="Pesquisar usuários pelo Nome ou E-mail" 
                           ValueChange={value => setSearch(value)}
                        />
                        <DefaultButton
                            bg="confirm"
                            to="/accounts/register"
                            text="Cadastrar novo usuário"
                        />
                    </div>
                    <Tabs
                        value={tab}
                        onChange={(_, value) => {
                            SetTab(value);
                            history.push({ search: `?tab=${value}` });
                        }}
                        style={{backgroundColor: "#ececf1"}}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Ativos"/>
                        <Tab label="Inativos"/>
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        <div className="table-responsive">
                            <table className="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>Tipo</th>
                                        <th>Data criado</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {!loading && users}
                                </tbody>
                            </table>
                            <div className="w100 flex align-center jcc">
                                {users.length == 0 && !loading ? "Nenhum usuário achado" : ""}
                            </div>
                        </div>
                        <div className="flex align-center jcc w100">
                            <CircularProgress className={loading ? "" : "hide"}/>
                        </div>
                        <Pagination
                            className={(max_page == 1 ? "hide" : "")}
                            style={{ display: "flex", justifyContent: "flex-end" }}
                            count={max_page}
                            value={page}
                            onChange={(_, value) => {
                                setPage(value);
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <InactiveUsersTable search={search_term} />
                    </TabPanel>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                autoHideDuration={2000}
                onClose={() => SetShowSnackbar(false)}
                open={showSnackbar}
                message={snackbarMessage}
            />
        </div>
    );
}

export default UserList;