import { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Request from "../../../../Request";
import DefaultButton from "../../../ReusableComponents/DefaultButton";
import Board from "./Board";
import { CircularProgress } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';

const InactiveUsersTable = props => {

    const {
        search
    } = props;

    const [users, SetUsers] = useState([]);
    const [loading, SetLoading] = useState(false);
    const [max_page, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);

    const GetData = async () => {
        SetLoading(true);
        let response = await Request.Get(`register/inactive?search=${search}&page=${page}`)
        SetLoading(false);
        console.log("inactive");
        console.log(response?.users);
        if (response?.status === true) {
            SetUsers(response?.users);
            setMaxPage(response.pagination["lastPage"]);

        }
    }

    useEffect(GetData, [search,page]);

    return (
        <div className="inativeTableContainer">
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Tipo</th>
                            <th>Data criado</th>
                            <th>Data inativado</th>
                            <th>Justificativa</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading && users?.map((user, userKey) => (
                            <Board
                                key={userKey}
                                id={user?.id}
                                name={user?.name}
                                email={user?.email}
                                justify={user?.justify}
                                role={user?.role?.name}
                                createdAt={user?.created_at}
                                deletedAt={user?.deleted_at}
                                onUpdate={GetData}
                            />
                        ))}
                    </tbody>
                </table>
                <div className="w100 flex align-center jcc">
                    {users.length == 0 && !loading ? "Nenhum usuário achado" : ""}
                </div>
                <div className="flex align-center jcc w100">
                                            <CircularProgress className={loading ? "" : "hide"}/>
                                        </div>
                <Pagination
                                            className={(max_page == 1 ? "hide" : "")}
                                            style={{ display: "flex", justifyContent: "flex-end" }}
                                            count={max_page}
                                            value={page}
                                            onChange={(_, value) => {
                                                setPage(value);
                                            }}
                                        />
            </div>
        </div>
    );
}

export default InactiveUsersTable;