import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Request from "../../../../../Request";
import DefaultButton from "../../../../ReusableComponents/DefaultButton";
import RestoreIcon from '@material-ui/icons/Restore';

const Board = props => {

    const {
        id,
        name,
        email,
        role,
        justify,
        createdAt,
        deletedAt,
        onUpdate = () => {},
        ...others
    } = props;

    const [popup, SetPopup] = useState(false);

    const Reactive = async () => {
        let form = new FormData();
        form.append("id", id);
        let response = await Request.Post("register/reactive", form);
        onUpdate();
    }

    return (
        <tr>
            <td>{name}</td>
            <td>{email}</td>
            <td>{role}</td>
            <td>{new Date(Date.parse(createdAt)).toLocaleDateString("pt-BR")}</td>
            <td>{new Date(Date.parse(deletedAt)).toLocaleDateString("pt-BR")}</td>
            <td>{justify?justify.justify:'-'}</td>

            <td style={{
                verticalAlign: "middle",
                whiteSpace: "nowrap"
            }}>
                <SweetAlert
                    show={popup}
                    title="Atenção!"
                    onConfirm={() => {
                        SetPopup(false);
                        Reactive();
                    }}
                    onCancel={() => {
                        SetPopup(false);
                    }}
                    showCancel={true}
                    cancelBtnStyle={{ color: "white", textDecoration: "none" }}
                    cancelBtnCssClass="btn-danger"
                    cancelBtnText="Cancelar"
                    confirmBtnText="Reativar"
                >
                    <p>Deseja mesmo reativar a conta de "{name}"?</p>
                </SweetAlert>
                <DefaultButton
                    onClick={() => SetPopup(true)}
                    width="2.2em"
                    height="2.2em"
                    padding={0}
                    title={`Reativar conta`}
                    bg="confirm"
                    icon={<RestoreIcon colorHtml="white" style={{ fontSize: 17 }}/>}
                />
            </td>
        </tr>
    );
}

export default Board;
